import { MY_PLAYERS_PAGE } from 'constants/pages';
import ReflectionNotificationTemplate from './ReflectionNotificationTemplate';
import { CompleteReflectionForCoachNotificationProps } from '../types';

const CompleteReflectionForCoachNotification: React.FC<
  CompleteReflectionForCoachNotificationProps
> = ({ status, notification }) => {
  const lesson = notification?.notificationDetails?.primaryEntity.lesson;

  return (
    <ReflectionNotificationTemplate
      href={MY_PLAYERS_PAGE}
      lesson={lesson}
      title="Complete Reflection"
      status={status}
      badgeComponent={
        <div className="rounded-xl bg-color-brand-primary px-2 text-xs leading-5 text-color-text-lightmode-invert">
          Pending
        </div>
      }
      message={'You completed a lesson recently. Fill out a lesson reflection as soon as you can!'}
    />
  );
};

export default CompleteReflectionForCoachNotification;
