import * as React from 'react';

export default function ShareV2({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 21 20'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Share">
        <path
          id="Icon"
          d="M15.5 8C17.1569 8 18.5 6.65685 18.5 5C18.5 3.34315 17.1569 2 15.5 2C13.8431 2 12.5 3.34315 12.5 5C12.5 5.12548 12.5077 5.24917 12.5227 5.37061L7.58259 7.84064C7.04303 7.32015 6.3089 7 5.5 7C3.84315 7 2.5 8.34315 2.5 10C2.5 11.6569 3.84315 13 5.5 13C6.30892 13 7.04306 12.6798 7.58263 12.1593L12.5227 14.6293C12.5077 14.7508 12.5 14.8745 12.5 15C12.5 16.6569 13.8431 18 15.5 18C17.1569 18 18.5 16.6569 18.5 15C18.5 13.3431 17.1569 12 15.5 12C14.6911 12 13.957 12.3201 13.4174 12.8406L8.47733 10.3706C8.4923 10.2492 8.5 10.1255 8.5 10C8.5 9.8745 8.49229 9.7508 8.47733 9.62934L13.4174 7.15932C13.9569 7.67984 14.6911 8 15.5 8Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
