import { MY_TRAINING_LOGS_PAGE } from 'constants/pages';
import ReflectionNotificationTemplate from './ReflectionNotificationTemplate';
import { NewReflectionForPlayerNotificationProps } from '../types';

const NewReflectionForPlayerNotification: React.FC<NewReflectionForPlayerNotificationProps> = ({
  status,
  notification,
}) => {
  const lesson = notification?.notificationDetails?.primaryEntity.lesson;

  return (
    <ReflectionNotificationTemplate
      href={`${MY_TRAINING_LOGS_PAGE}?lessonId=${lesson?.id}`}
      lesson={lesson}
      title="New Reflection"
      status={status}
      badgeComponent={
        <div className="rounded-xl bg-color-brand-primary px-2 text-xs leading-5 text-color-text-lightmode-invert">
          Completed
        </div>
      }
      message={`Your coach, ${lesson?.ownerProfile?.fullName} has completed a reflection for your most recent lesson. Check it out now!`}
    />
  );
};

export default NewReflectionForPlayerNotification;
