import * as React from 'react';

export default function LogoNav({
  className,
  viewBox = '0 0 132 30',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="22" r="8" fill="url(#paint0_radial_9519_32324)" />
      <circle cx="8" cy="18" r="8" fill="url(#paint1_radial_9519_32324)" />
      <path
        d="M0.00231314 3.5L0 14.0611C0.0328247 18.4512 3.60209 22 7.99999 22C12.4183 22 16 18.4183 16 14C16 9.58175 12.4183 6.00002 7.99999 6.00002C7.64131 6.00002 7.34387 6.02363 6.99769 6.06937L7 3.5C7 1.38385 5.26 -1.52588e-07 3.5 0C1.74 1.52588e-07 0.00231314 1.38385 0.00231314 3.5Z"
        fill="#FF5C33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9277 4.79883H29.6855V22.7266H33.8125V20.4205C34.9572 22.0503 36.6409 23.1402 38.8781 23.1402C42.7663 23.1402 45.5972 20.2981 45.5972 16.0462C45.5972 11.8 42.758 8.95221 38.8781 8.95221C36.7204 8.95221 35.0714 9.97549 33.9277 11.518V4.79883ZM37.6304 12.3224C39.6387 12.3224 41.3077 14.0392 41.3077 16.0462C41.3077 18.0724 39.6533 19.77 37.6304 19.77C35.4727 19.77 33.8872 18.1102 33.8872 16.0462C33.8872 13.9822 35.4932 12.3224 37.6304 12.3224Z"
        fill="#FF5C33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8669 16.0462C46.8669 21.0769 50.9777 23.1402 55.0812 23.1402C59.1974 23.1402 63.3179 21.0903 63.3179 16.0462C63.3179 11.0092 59.1881 8.95221 55.0812 8.95221C50.9757 8.95221 46.8669 11.0171 46.8669 16.0462ZM59.0285 16.0462C59.0285 18.4407 57.3077 19.8407 55.0812 19.8407C52.8654 19.8407 51.1334 18.424 51.1334 16.0462C51.1334 13.6827 52.8718 12.2517 55.0812 12.2517C57.3014 12.2517 59.0285 13.6661 59.0285 16.0462Z"
        fill="#FF5C33"
      />
      <path
        d="M70.737 23.1166C67.1398 23.1166 65.0099 20.4242 65.0099 16.824V9.3293H69.2535V16.1876C69.2535 18.079 70.2376 19.5578 72.1742 19.5578C74.2786 19.5578 75.4829 17.8917 75.4829 15.8105V9.3293H79.7265V22.7232H76.1976L75.6977 20.0697C74.6755 21.8552 72.9982 23.1166 70.737 23.1166Z"
        fill="#FF5C33"
      />
      <path
        d="M85.956 9.3293H82.3885V22.7232H86.6326V16.2819C86.6326 14.179 87.9516 12.5346 90.0551 12.5346C92.037 12.5346 93.1354 13.9496 93.1354 15.9284V22.7232H97.3794V15.2685C97.3794 11.6125 95.1027 8.95221 91.4927 8.95221C89.4102 8.95221 87.5762 10.079 86.4558 11.8628L85.956 9.3293Z"
        fill="#FF5C33"
      />
      <path
        d="M114.91 17.1775C114.508 21.0707 110.876 23.1402 107.038 23.1402C102.609 23.1402 99.0748 20.4897 99.0748 16.0462C99.0748 11.6027 102.609 8.95221 107.038 8.95221C110.874 8.95221 114.514 11.0217 114.91 14.9149H110.689C110.346 13.148 108.745 12.2517 107.038 12.2517C104.794 12.2517 103.342 13.8325 103.342 16.0462C103.342 18.2599 104.794 19.8407 107.038 19.8407C108.739 19.8407 110.33 18.9293 110.689 17.1775H114.91Z"
        fill="#FF5C33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.004 15.9991C116.004 20.5792 120.025 23.1402 124.104 23.1402C126.446 23.1402 128.843 22.5469 130.789 21.1605L129.239 18.5175C127.916 19.375 126.466 19.8902 124.914 19.8902C122.836 19.8902 121.086 19.2028 120.53 17.3424H130.965C131.5 12.6934 128.445 8.95221 123.785 8.95221C119.79 8.95221 116.004 11.5787 116.004 15.9991ZM120.391 14.585H127.124C126.747 13.1176 125.704 12.1103 123.853 12.1103C122.16 12.1103 120.793 12.9054 120.391 14.585Z"
        fill="#FF5C33"
      />
      <defs>
        <radialGradient
          id="paint0_radial_9519_32324"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8 26.08) rotate(-90) scale(11.7529)"
        >
          <stop stopColor="#FF5C33" stop-opacity="0.4" />
          <stop offset="1" stopColor="#FF5C33" stop-opacity="0.1" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_9519_32324"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8 22.08) rotate(-90) scale(11.7529)"
        >
          <stop stopColor="#FF5C33" stop-opacity="0.7" />
          <stop offset="1" stopColor="#FF5C33" stop-opacity="0.2" />
        </radialGradient>
      </defs>
    </svg>
  );
}
