import React from 'react';
import * as Sentry from '@sentry/nextjs';
import {
  GetUserNotificationsQuery,
  NotificationStatusesEnum,
  useGetUserNotificationsCountLazyQuery,
  useGetUserNotificationsLazyQuery,
  useUpdateNotificationsAsReadMutation,
} from 'types/generated/client';
import { useViewer } from 'hooks/useViewer';
import SectionHeading from 'components/SectionHeading';
import classNames from 'styles/utils/classNames';
import NotificationTypeSelector from './components/NotificationTypeSelector';

interface NotificationsProps {
  isModal?: boolean;
}

const Notifications = ({ isModal = false }: NotificationsProps) => {
  const PAGE_LIMIT = isModal ? 3 : 20;
  const viewer = useViewer();
  const [getUserNotificationsCountLazyQuery, { data: countData }] =
    useGetUserNotificationsCountLazyQuery();
  const [
    getUserNotificationsLazyQuery,
    { data: notificationsData, loading: notificationsLoading, fetchMore: fetchMoreNotifications },
  ] = useGetUserNotificationsLazyQuery();
  const [updateNotificationsAsReadMutation] = useUpdateNotificationsAsReadMutation();
  const notificationCount = countData?.userNotificationsAggregate.aggregate?.count ?? 0;
  const notifications = notificationsData?.userNotifications || [];

  const hasMoreNotifications = notifications.length < notificationCount;

  const setNotificationsAsRead = async (data: GetUserNotificationsQuery | undefined) => {
    const readAt = new Date().toISOString();
    const newNotifications = data?.userNotifications || [];
    const updates = newNotifications.map((notification) => ({
      _set: { readAt: readAt, status: NotificationStatusesEnum.Read },
      where: {
        id: {
          _eq: notification.id,
        },
      },
    }));
    return updateNotificationsAsReadMutation({
      variables: {
        updates,
      },
    });
  };

  const handleFetchMoreNotifications = () => {
    fetchMoreNotifications({
      variables: {
        userId: viewer.userId,
        offset: notifications.length,
        limit: PAGE_LIMIT,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        return {
          ...previousResult,
          userNotifications: [
            ...(previousResult.userNotifications || []),
            ...(fetchMoreResult.userNotifications || []),
          ],
        };
      },
    })
      .then((res) => setNotificationsAsRead(res.data))
      .catch((error) => Sentry.captureException(error));
  };

  React.useEffect(() => {
    if (viewer.userId) {
      getUserNotificationsCountLazyQuery({
        variables: {
          userId: viewer.userId,
        },
      }).catch((error) => Sentry.captureException(error));
      getUserNotificationsLazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        variables: {
          userId: viewer.userId,
          offset: 0,
          limit: PAGE_LIMIT,
        },
      })
        .then((res) => setNotificationsAsRead(res.data))
        .catch((error) => Sentry.captureException(error));
    }
  }, [viewer.userId]);

  return (
    <>
      {!isModal && (
        <div className="xs:my-4 xs:mt-2 md:mb-2 md:mt-4">
          {notifications.length > 0 ? <SectionHeading>Latest</SectionHeading> : ''}
        </div>
      )}
      <div className={classNames('xs:space-y-4  md:space-y-3', !isModal && 'md:mb-8')}>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationTypeSelector key={notification.id} notification={notification} />
          ))
        ) : (
          <span className="flex min-h-96 items-center justify-center text-center">
            No new notifications
          </span>
        )}
      </div>
      {hasMoreNotifications && !isModal && (
        <div className="flex justify-center xs:mb-36 xs:mt-6 md:my-6">
          <button
            type="button"
            className="button-rounded-inline-brand-inverted px-4"
            disabled={notificationsLoading}
            onClick={handleFetchMoreNotifications}
          >
            {notificationsLoading ? 'Loading...' : 'Load more'}
          </button>
        </div>
      )}
    </>
  );
};

export default Notifications;
