import * as React from 'react';
import {
  COACH_MARKETPLACE_PAGE,
  TOURNAMENTS_PAGE,
  TOURNAMENT_QUICK_CREATE_PAGE,
} from 'constants/pages';
import { useModal } from 'hooks/useModal';
import CloseIcon from 'svg/CloseIcon';
import CoachBadge from 'svg/CoachBadge';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import SearchIcon from 'svg/SearchIcon';
import VictoryCupSimple from 'svg/VictoryCupSimple';
import { Button, ButtonLink } from 'components/Button';
import Modal from 'components/modals/Modal';
import ModalInvite from 'components/modals/ModalInvite';

interface Props {
  isOpen: boolean;
  closeModal: (value: boolean) => void;
  title: string;
  buttonText?: string;
  triggerPage?: (value: string) => void;
}

export default function ModalPlayerActions({
  isOpen,
  closeModal,
  title,
  buttonText = 'Close',
  triggerPage,
}: Props) {
  const shareUrl = typeof window !== 'undefined' ? window.location.href : '';

  const {
    openModal: openInviteModal,
    closeModal: closeInviteModal,
    isOpen: isInviteModalOpen,
  } = useModal();
  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => closeModal(false)}
      classNameMaxWidth="md:max-w-[25rem] xs:max-w-md"
    >
      <div className="flex flex-col  justify-between gap-8 pt-6 xs:px-4 xs:pb-6 md:px-6 md:pb-6">
        <div className="flex flex-row justify-between text-left">
          <h3 className="typography-product-heading-compact text-left text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            {title}
          </h3>

          <button
            className="h-6 w-6 p-0.5 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary"
            type="button"
            onClick={() => closeModal(false)}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="flex flex-col xs:gap-2 md:gap-4">
          <Button
            variant="primary"
            className="w-full"
            size="lg"
            onClick={() => triggerPage && triggerPage(TOURNAMENT_QUICK_CREATE_PAGE)}
            iconLeft={<VictoryCupSimple className="h-5 w-5" />}
          >
            <span className="flex flex-row items-center justify-between">Create a round robin</span>
          </Button>
          <ButtonLink
            variant="primary"
            className="w-full"
            size="lg"
            href={TOURNAMENTS_PAGE}
            iconLeft={<SearchIcon className="h-5 w-5" />}
          >
            <span className="flex flex-row items-center justify-between">Explore near me</span>
          </ButtonLink>

          <Button
            variant="primary"
            className="flex w-full items-center justify-center"
            size="lg"
            onClick={() => openInviteModal()}
            iconLeft={<PlusWithoutBG className="h-5 w-5" />}
          >
            Invite players
          </Button>
          <Button
            variant="brand"
            className="w-full"
            size="lg"
            onClick={() => triggerPage && triggerPage(COACH_MARKETPLACE_PAGE)}
            iconLeft={<CoachBadge className="h-5 w-5" />}
          >
            Book a lesson
          </Button>
        </div>
        <ModalInvite
          isOpen={isInviteModalOpen}
          closeModal={closeInviteModal}
          shareUrl={shareUrl}
          title="Share this link"
        />
      </div>
    </Modal>
  );
}
