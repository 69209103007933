import React from 'react';
import { format } from 'date-fns';
import { MY_TRAINING_LOGS_PAGE } from 'constants/pages';
import Link from 'components/Link';
import CardNotification from './CardNotification';
import { ReflectionNotificationTemplateProps } from '../types';

export default function ReflectionNotificationTemplate({
  status,
  badgeComponent,
  message,
  lesson,
  title,
  href,
}: ReflectionNotificationTemplateProps) {
  const [initialStatus] = React.useState(status);

  return (
    <Link href={href} className="block">
      <CardNotification status={initialStatus}>
        <div className="p-2">
          <div className="flex items-center justify-between">
            <span className="text-sm font-bold">{title}</span>
            {badgeComponent}
          </div>

          <div className="mt-1 text-xs leading-5">{message}</div>

          <div className="flex gap-2 text-xs">
            <span className="text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              Submitted on
            </span>
            {lesson?.endDateTime && (
              <span className="text-color-brand-primary">
                {format(new Date(lesson?.endDateTime), 'MMM d')}
              </span>
            )}
          </div>
        </div>
      </CardNotification>
    </Link>
  );
}
