import { toTitleCase } from 'utils/shared/toTitleCase';
import VideoAnalysisNotificationTemplate from './VideoAnalysisNotificationTemplate';
import { VideoAnalysisForCoachNotificationProps } from '../types';

const VideoAnalysisForCoachNotification: React.FC<VideoAnalysisForCoachNotificationProps> = ({
  status,
  notification,
}) => {
  const actorFullName = toTitleCase(
    notification?.notificationDetails?.primaryEntity?.actingUserProfile?.fullName || '',
  );

  return (
    <VideoAnalysisNotificationTemplate
      showLessonPrice
      videoAnalysis={notification?.notificationDetails?.primaryEntity.videoAnalysis}
      status={status}
      actorFullName={actorFullName}
      badgeComponent={
        <div className="rounded-xl bg-color-brand-primary px-2 text-xs leading-5 text-color-text-lightmode-invert">
          Submiited
        </div>
      }
      message={`${actorFullName} submitted a video.`}
    />
  );
};

export default VideoAnalysisForCoachNotification;
