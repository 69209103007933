import * as React from 'react';
import { useRouter } from 'next/router';
import { AuthStatus } from 'constants/auth';
import {
  COACH_HOME_PAGE,
  HOME_PAGE,
  HOME_PAGE_REWRITE,
  LOGIN_PAGE,
  MY_PLAYERS_PAGE,
  MY_PROFILE_PAGE,
  MY_SCHEDULE_PAGE,
  MY_YOU_PAGE,
  ROOT_PAGE,
  SIGNUP_CODE_PAGE,
  getCoachPageUrl,
} from 'constants/pages';
import { CoachStatusEnum } from 'types/generated/client';
import { getProfileImageUrlOrPlaceholder } from 'utils/shared/user/getProfileImageUrlOrPlaceholder';
import { useAuthModals } from 'hooks/useAuthModals';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import { useViewer } from 'hooks/useViewer';
import Logo from 'svg/LogoNav';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import { Button, ButtonLink, ButtonLinkText, ButtonText } from 'components/Button';
import Link from 'components/Link';
import NotificationIndicator from 'components/NotificationIndicator';
import TransitionFadeIn from 'components/TransitionFadeIn';
import ModalCoachActions from 'components/modals/ModalCoachActions';
import ModalNotification from 'components/modals/ModalNotification';
import ModalPlayerActions from 'components/modals/ModalPlayerActions';
import classNames from 'styles/utils/classNames';

interface Props {
  shouldShowAdditionalLinks?: boolean;
  shouldHideNavigation: boolean;
  shouldShowMobile?: boolean;
  shouldLinkToAuthPage?: boolean;
  shouldShowStartAction?: boolean;
  isBlur?: boolean;
  isInvert?: boolean;
  isTransparent?: boolean;
  handleSignupSuccess?: ({ userId }: { userId: string }) => void | Promise<void>;
}

export default function TopNav({
  shouldShowAdditionalLinks,
  shouldShowMobile,
  shouldHideNavigation,
  shouldLinkToAuthPage = false,
  shouldShowStartAction,
  isBlur,
  isInvert,
  isTransparent,
  handleSignupSuccess,
}: Props) {
  const viewer = useViewer();
  const router = useRouter();
  const { ModalLogin, ModalSignup, openSignupModal, openLoginModal } = useAuthModals();
  const { user, loading, called } = useGetCurrentUser();
  const isViewerLoaded = viewer.status !== AuthStatus.Loading;
  const isAnon = viewer.status === AuthStatus.Anonymous;
  const isUser = viewer.status === AuthStatus.User;
  const isCoach = user?.coachStatus === CoachStatusEnum.Active;
  const isAnonLoaded = isViewerLoaded;
  const isUserLoaded = isViewerLoaded && !loading && called;
  const isShowing = (isAnon && isAnonLoaded) || (isUser && isUserLoaded);
  const {
    isOpen: isModalCoachActionsOpen,
    openModal: openCoachActionsModal,
    closeModal: closeCoachActionsModal,
  } = useModal();

  const {
    isOpen: isModalNotificationOpen,
    openModal: openModalNotification,
    closeModal: closeModalNotification,
  } = useModal();

  const {
    isOpen: isModalPlayerActionsOpen,
    openModal: openPlayerActionsModal,
    closeModal: closePlayerActionsModal,
  } = useModal();

  const triggerPage = (url: string) => {
    if (isAnon) {
      openLoginModal();
    } else {
      router.push(url);
    }
  };

  const NavLink = ({
    href,
    children,
    isActive,
    isInvert,
  }: {
    href: string;
    children: React.ReactNode;
    isActive?: boolean;
    isInvert?: boolean;
  }) => {
    return (
      <Link
        href={href}
        className={classNames(
          'typography-product-body hover:typography-product-subheading group relative flex w-[90px] flex-col items-center justify-center rounded-full px-ds-lg py-2 transition-colors',
          isInvert
            ? ''
            : isActive
            ? 'typography-product-subheading text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
            : 'typography-product-body text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
          isInvert
            ? isActive
              ? 'typography-product-subheading text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
              : 'typography-product-body text-color-text-darkmode-secondary dark:text-color-text-lightmode-secondary'
            : '',
        )}
      >
        {children}
      </Link>
    );
  };

  return (
    <>
      <div className={classNames('block w-full')}>
        <div
          className={classNames(
            'fixed left-0 top-0 z-40 h-topnav w-full',
            isBlur && 'bg-opacity-80 backdrop-blur-md',
            isTransparent
              ? 'bg-transparent'
              : isInvert
              ? 'bg-color-bg-lightmode-invert dark:bg-color-bg-darkmode-invert'
              : ' border-b border-color-border-input-lightmode bg-color-bg-lightmode-primary dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary',
          )}
        >
          <div className="flex h-full w-full items-center justify-between">
            <div className="relative z-10 flex h-full items-center pl-4 sm:pl-8">
              <Link
                href={isUser ? HOME_PAGE : ROOT_PAGE}
                className="inline-block"
                aria-label="Home"
              >
                <div className="flex h-full items-center">
                  <Logo className="h-6" />
                </div>
              </Link>
            </div>
            <div className="absolute left-0 right-0 mx-auto flex h-full w-full items-center justify-center ">
              <div className="h-12 flex-row gap-3 rounded-full border border-color-border-input-lightmode shadow-sm dark:border-color-border-input-darkmode xs:hidden md:flex">
                {!shouldHideNavigation && !isCoach && (
                  <div className="mx-3 hidden h-full items-center xs:gap-0 md:flex lg:gap-3">
                    <NavLink
                      isActive={
                        router.asPath === ROOT_PAGE || router.asPath.startsWith(HOME_PAGE_REWRITE)
                      }
                      href={ROOT_PAGE}
                      isInvert={isInvert}
                    >
                      Home
                    </NavLink>
                    <div className="px-ds-lg py-2">
                      <Button
                        variant="brand"
                        className="flex h-10 w-10 shrink-0 grow-0 rounded-full border-none px-2.5 shadow-lg focus:outline-none "
                        onClick={() => openPlayerActionsModal(true)}
                        iconLeft={<PlusWithoutBG className="h-6 w-6 cursor-pointer" />}
                        size="icon"
                      />
                    </div>
                    <NavLink
                      isActive={router.pathname === MY_YOU_PAGE}
                      href={MY_YOU_PAGE}
                      isInvert={isInvert}
                    >
                      You
                    </NavLink>
                  </div>
                )}
                {!shouldHideNavigation && isCoach && (
                  <div className="mx-3 hidden h-full items-center xs:gap-0 md:flex lg:gap-3">
                    <NavLink
                      isActive={
                        router.asPath === COACH_HOME_PAGE ||
                        router.asPath.startsWith(HOME_PAGE_REWRITE)
                      }
                      href={COACH_HOME_PAGE}
                      isInvert={isInvert}
                    >
                      Home
                    </NavLink>
                    <NavLink
                      isActive={router.pathname === MY_SCHEDULE_PAGE}
                      href={MY_SCHEDULE_PAGE}
                      isInvert={isInvert}
                    >
                      Schedule
                    </NavLink>
                    <div className="px-ds-lg py-2">
                      <Button
                        variant="brand"
                        className="flex h-10 w-10 shrink-0 grow-0 rounded-full border-none px-2.5 shadow-lg focus:outline-none "
                        onClick={() => openCoachActionsModal(true)}
                        isInline
                        size="icon"
                        iconLeft={<PlusWithoutBG className="h-6 w-6 cursor-pointer" />}
                      />
                    </div>
                    <NavLink
                      isActive={router.pathname === MY_PLAYERS_PAGE}
                      href={MY_PLAYERS_PAGE}
                      isInvert={isInvert}
                    >
                      Players
                    </NavLink>
                    <NavLink
                      isActive={router.asPath === getCoachPageUrl(user.username || user.id)}
                      href={getCoachPageUrl(user.username || user.id)}
                      isInvert={isInvert}
                    >
                      Listing
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
            <div className="relative z-10 flex h-full flex-row items-center gap-4 pr-4 sm:pr-8">
              <TransitionFadeIn className="" isShowing={isShowing}>
                {isAnon ? (
                  <div className="flex h-full shrink-0 items-center space-x-2">
                    {shouldLinkToAuthPage ? (
                      <>
                        <ButtonLinkText
                          href={LOGIN_PAGE}
                          size="md"
                          className={classNames(
                            'rounded-full px-4 py-2.5 font-medium transition-colors hover:bg-color-bg-lightmode-secondary hover:dark:bg-color-bg-darkmode-secondary',
                            isInvert
                              ? 'text-color-text-darkmode-secondary dark:text-color-text-lightmode-secondary'
                              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
                          )}
                        >
                          Log in
                        </ButtonLinkText>
                        <ButtonLink
                          href={SIGNUP_CODE_PAGE}
                          variant={isInvert ? 'inverted' : 'primary'}
                          size="sm"
                          isInline
                        >
                          Sign up
                        </ButtonLink>
                      </>
                    ) : (
                      <>
                        <ButtonText
                          onClick={() => openLoginModal(true)}
                          size="md"
                          className={classNames(
                            'rounded-full px-4 py-2.5 font-medium transition-colors hover:bg-color-bg-lightmode-secondary hover:dark:bg-color-bg-darkmode-secondary',
                            isInvert
                              ? 'text-color-text-darkmode-secondary dark:text-color-text-lightmode-secondary'
                              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
                          )}
                        >
                          Log in
                        </ButtonText>
                        <Button
                          onClick={() => openSignupModal(true)}
                          variant={isInvert ? 'inverted' : 'primary'}
                          size="sm"
                          isInline
                        >
                          Sign up
                        </Button>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-center gap-4">
                    <div className="flex h-full shrink-0 items-center">
                      {!!shouldShowStartAction && (
                        <ButtonLink
                          href={HOME_PAGE}
                          size="sm"
                          variant="brand"
                          isInline
                          className="ml-4 mr-4 inline"
                        >
                          Get started
                        </ButtonLink>
                      )}
                      <div className="flex flex-row items-center justify-center gap-6">
                        <div className="items-right flex w-full gap-10 self-center py-2">
                          <div className="my-auto flex w-full flex-1 shrink basis-0 items-center justify-between gap-10 self-stretch">
                            <NotificationIndicator
                              className="h-5 w-5 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary"
                              onClick={() => openModalNotification(true)}
                            />
                          </div>
                        </div>
                        <Link
                          href={
                            isCoach ? getCoachPageUrl(user.username || user.id) : MY_PROFILE_PAGE
                          }
                          className="block shrink-0 rounded-full"
                        >
                          <img
                            className="h-8 w-8 rounded-full"
                            src={getProfileImageUrlOrPlaceholder({ path: user?.profileImagePath })}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </TransitionFadeIn>
            </div>
          </div>
        </div>
        {!isTransparent && (
          <div
            className={classNames(
              'h-topnav w-full',
              isBlur &&
                !isInvert &&
                'bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary',
              isBlur && isInvert && 'bg-color-bg-lightmode-invert dark:bg-color-bg-darkmode-invert',
            )}
          >
            &nbsp;
          </div>
        )}
      </div>
      <ModalSignup handleSignupSuccess={handleSignupSuccess} isShowLoginLink />
      <ModalLogin isShowSignupLink />
      <ModalCoachActions
        isOpen={isModalCoachActionsOpen}
        closeModal={closeCoachActionsModal}
        title="Actions"
      />
      <ModalNotification
        isOpen={isModalNotificationOpen}
        closeModal={closeModalNotification}
        title="Notifications"
      />
      <ModalPlayerActions
        isOpen={isModalPlayerActionsOpen}
        closeModal={closePlayerActionsModal}
        title="Actions"
        triggerPage={triggerPage}
      />
    </>
  );
}
