import React from 'react';
import { MY_NOTIFICATIONS_PAGE } from 'constants/pages';
import CloseIcon from 'svg/CloseIcon';
import { ButtonLink } from 'components/Button';
import Notifications from 'components/Notifications';
import Modal from 'components/modals/Modal';

interface Props {
  isOpen: boolean;
  closeModal: (value: boolean) => void;
  title: string;
  buttonText?: string;
}

export default function ModalNotification({ isOpen, closeModal, title }: Props) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={() => closeModal(false)}
        classNameMaxWidth="md:max-w-[40rem] xs:max-w-md"
      >
        <main className="relative flex w-full flex-col">
          <div className="flex flex-row items-center justify-between border-color-border-input-lightmode dark:border-color-border-input-darkmode xs:p-5 md:border-b md:px-10 md:py-5">
            <h3 className="typography-product-heading text-left text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              {title}
            </h3>

            <button
              className="text-color-text-lightmode-primary dark:text-color-text-darkmode-primary"
              type="button"
              onClick={() => closeModal(false)}
            >
              <CloseIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="xs:gap-6 xs:px-5 xs:py-6 md:gap-8 md:px-10 md:pb-10 md:pt-6">
            <Notifications isModal={true} />
          </div>
          <div className="flex flex-row items-center justify-center border-color-border-input-lightmode dark:border-color-border-input-darkmode xs:px-4 xs:pb-8 xs:pt-5 md:border-t md:px-10 md:py-5">
            <ButtonLink
              href={MY_NOTIFICATIONS_PAGE}
              variant="brand"
              label="View all notifications"
              size="lg"
              className="xs:w-full md:w-60"
            />
          </div>
        </main>
      </Modal>
    </>
  );
}
